:root {
    --button-color-dark-blue: #0456b3;
    --sidebar-selected-background-color: #D6E0FF;
    --sidebar-text-color: #0051c3;
    --sidebar-setting-text-color: #000000;
    --main-blue-color: #056DFF;
    --main-red-color: #ED1A3D;
    --button-color-dark-red: #D93A49;
}

.undoButton {
    border-radius: 0.375rem;
    border: 1px solid #d1d5db;
    background-color: white;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
}

.undoButton:hover {
    background-color: #f9fafb;
}

.button {
    border-radius: 0.375rem;
    background-color: var(--main-blue-color);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
    min-width: 80px;
}

.button:hover {
    background-color: var(--button-color-dark-blue);
    color: white;
}

.buttonDanger {
    border-radius: 0.375rem;
    background-color: var(--main-red-color);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition: background-color 0.2s;
    min-width: 80px;
}

.buttonDanger:hover {
    background-color: var(--button-color-dark-red);
    color: white;
}

.selectTabSelected {
    border-color: var(--main-blue-color);
    color: var(--main-blue-color);
    white-space: nowrap;
    border-bottom-width: 2px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.selectTabNotSelected {
    border-color: transparent;
    color: #6B7280;
    white-space: nowrap;
    border-bottom-width: 2px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
}
.selectTabNotSelected:hover {
    border-color: #D1D5DB;
    color: #374151;
}

.formInput {
    display: block;
    width: 100%;
    min-width: 0;
    flex: 1;
    border-radius: 0.375rem;
    border: 1px solid #D1D5DB;
    font-size: 0.875rem;
}

.formInput:focus {
    border-color: #468FBC;
    box-shadow: 0 0 0 1px var(--main-blue-color);
}

.formInputCheckBox {
    height: 1rem;
    width: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #D1D5DB;
    color: var(--main-blue-color);
}

.sidebar {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    width: 256px;
}

.topBarUserSection {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background-color: white;
    border-radius: 9999px;
    outline: none;
}

.languageSelectBox {
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    border-radius: 0.375rem;
    border: 0;
    padding: 0.375rem 2.5rem 0.375rem 0.75rem;
    color: #1f2937;
    outline: #d1d5db solid 1px;
    &:focus {
        outline-offset: 2px;
        outline-color: var(--main-blue-color);
    }
    @media (min-width: 640px) {
        font-size: 0.875rem;
        line-height: 1.5;
    }
}

@media (min-width: 640px) {
    .formInput {
        font-size: 0.875rem;
    }
}
